import { graphql, useStaticQuery } from "gatsby"

const useBlogConfig = () => {
  const data = useStaticQuery(graphql`
    query {
      blogConfig {
        url
        basePath
        blogPath
        booksPath
        tagsPath
        postFilesPath
        bookFilesPath
        userTwitterHandle
        externalLinks {
          name
          url
        }
        navigation {
          title
          slug
        }
        showLineNumbers
      }
    }
  `)

  return data.blogConfig
}

export default useBlogConfig
