module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[{"resolve":"/home/venelin/Dev/projects/blog/node_modules/gatsby-remark-images","id":"3626c0e0-58d4-5046-b0e5-cb7731a052f2","name":"gatsby-remark-images","version":"3.3.35","pluginOptions":{"plugins":[]},"nodeAPIs":[],"browserAPIs":["onRouteUpdate"],"ssrAPIs":[]},{"resolve":"/home/venelin/Dev/projects/blog/node_modules/gatsby-remark-images-medium-zoom","id":"28541d58-07fa-56b4-b9e4-03d3a0028bef","name":"gatsby-remark-images-medium-zoom","version":"1.7.0","pluginOptions":{"plugins":[]},"nodeAPIs":[],"browserAPIs":["onRouteUpdate"],"ssrAPIs":[]}],"extensions":[".mdx",".md"],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"maxWidth":860,"linkImagesToOriginal":false,"showCaptions":["title","alt"],"quality":100}},{"resolve":"gatsby-remark-images-medium-zoom","options":{}},{"resolve":"gatsby-remark-external-links","options":{"target":"_blank"}},{"resolve":"/home/venelin/Dev/projects/blog/gatsby-remark-embed-source-code.js"},"gatsby-remark-embedder","gatsby-remark-katex","gatsby-remark-copy-linked-files","gatsby-remark-smartypants","gatsby-remark-autolink-headers"]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-remark-images-medium-zoom/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/@pauliescanlon/gatsby-mdx-embed/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-63986975-1"],"pluginConfig":{"head":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Curiousily - Hacker's Guide to Machine Learning","short_name":"Curiousily","description":"Tutorials on Machine Learning, Deep Learning, Statistics &amp; Programming","start_url":"/","background_color":"#fff","theme_color":"#6B46C1","display":"standalone","icons":[{"src":"/android-chrome-192x192.png","sizes":"192x192","type":"image/png"},{"src":"/android-chrome-512x512.png","sizes":"512x512","type":"image/png"}],"cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":null},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
